import React, { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';

import api from '../../services/api';

import { useToast } from '../../hooks/ToastContext';

import Header from '../../components/Header';

import * as S from './styles';

interface IParams {
    id: string;
}

interface IPlans {
    id: string;
    name: string;
    description: string;
    discount_price: number;
}

const Customer: React.FC = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [profile, setProfile] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState<IPlans[]>([]);
    const [statusCustomer, setStatusCustomer] = useState('');
    const [lastSend, setLastSend] = useState('');
    const [planCurrent, setPlanCurrent] = useState('');

    const { addToast } = useToast();

    const { id } = useParams<IParams>();

    useEffect(() => {
        api.get(`customers/${id}`).then(response => {
            const { name, email, phone, profile, sale_price, status, last_send, plan_id } = response.data;
            setName(name);
            setEmail(email);
            setPhone(phone);
            setProfile(profile);
            setSalePrice(sale_price);
            setStatusCustomer(status);
            setLastSend(last_send);
            setPlanCurrent(plan_id);
        });
    }, [id]);

    useEffect(() => {
        api.get(`plans/${id}`).then(response => {
            setPlans(response.data);
        })
    }, [id, loading]);

    function formatDate() {

        const date = lastSend.replace('.', 'T').split("T");
        const dateFormated = `${date[0]} ${date[1]}`;

        return dateFormated;
    }

    function updateCustomer() {
        setLoading(true);
        api.put('customers', {
            customer_id: id,
            name,
            email,
            phone,
            profile,
            status: statusCustomer
        }).then(response => {

            if (response.status === 204) {
                setLoading(false);
                addToast({
                    type: 'success',
                    title: 'Dados alterado com sucesso'
                });
            } else {
                setLoading(false);
                addToast({
                    type: 'error',
                    title: 'Não foi possível alterar',
                    description: 'Favor tentar novamente ou entrar em contato com o suporte'
                });
            }
        });
    }

    return (
        <S.Container>
            <Header />
            <S.Content>
                <S.ContentBody>
                    <S.ContentTitlePlanAndAdd>
                        <S.TitleDiscountUserPlan>Informações do cliente:</S.TitleDiscountUserPlan>
                    </S.ContentTitlePlanAndAdd>
                    <S.Form>
                        <S.ContentForm>
                            <S.ContentInput>
                                <S.Label>Nome:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={name}
                                    placeholder="Ex: João Silva"
                                    onChange={e => setName((e.target as HTMLInputElement).value)}
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>E-mail:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={email}
                                    placeholder="Ex: contato@agenciagram.com.br"
                                    onChange={e => setEmail((e.target as HTMLInputElement).value)}
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>WhatsApp:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={phone}
                                    placeholder="Ex: (00) 99999-0000"
                                    onChange={e => setPhone((e.target as HTMLInputElement).value)}
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>Perfil:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={profile}
                                    placeholder="Ex: agenciagram"
                                    onChange={e => setProfile((e.target as HTMLInputElement).value)}
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>Plano:</S.Label>
                                <S.Select disabled>
                                    {plans.map((plan, index) => plan.id === planCurrent ? (
                                        <S.Option value={plan.id} selected>{plan.name}</S.Option>
                                    ) : (
                                        <S.Option value={plan.id}>{plan.name}</S.Option>
                                    ))}
                                </S.Select>
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>Preço de venda:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={"R$ " + (+salePrice).toFixed(2).replace('.', ',')}
                                    placeholder="Ex: R$ 49,90"
                                    disabled
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>Último envio:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={formatDate()}
                                    placeholder="Ex: R$ 49,90"
                                    disabled
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>Nível:</S.Label>
                                <S.Select onChange={e => setStatusCustomer((e.target as HTMLSelectElement).value)}>
                                    <S.Option value={statusCustomer} selected>{statusCustomer === 'active' ? 'Ativo' : 'Inativo'}</S.Option>
                                    {/* <S.Option value="active">Ativo</S.Option> */}
                                    <S.Option value="canceled">Inativo</S.Option>
                                </S.Select>
                            </S.ContentInput>
                        </S.ContentForm>
                    </S.Form>
                </S.ContentBody>

                <S.ContentBody>
                    {/* <S.ContentTitlePlanAndAdd>
                        <TitleDiscountUserPlan>Histórico de envíos:</TitleDiscountUserPlan>
                    </ContentTitlePlanAndAdd>

                    <S.ContentHistoric>
                        <RowPayment>
                            <DatePayment>2021-04-10 13:54:54</DatePayment>
                            <MethodPayment>agenciagram</MethodPayment>
                            <ValuePayment>Pendente</ValuePayment>
                        </RowPayment>

                        <RowPayment>
                            <DatePayment>2021-04-10 13:54:54</DatePayment>
                            <MethodPayment>agenciagram</MethodPayment>
                            <ValuePayment>Concluído</ValuePayment>
                        </RowPayment>

                        <RowPayment>
                            <DatePayment>2021-04-10 13:54:54</DatePayment>
                            <MethodPayment>agenciagram</MethodPayment>
                            <ValuePayment>Concluído</ValuePayment>
                        </RowPayment>
                    </ContentHistoric> */}

                    <S.ContentButtons>
                        <Link to="/customers">
                            <S.ButtonClose>Voltar</S.ButtonClose>
                        </Link>
                        <S.ButtonRegister
                            loading={loading}
                            onClick={updateCustomer}
                        >Salvar</S.ButtonRegister>
                    </S.ContentButtons>
                </S.ContentBody>
            </S.Content>
        </S.Container>
    );
}

export default Customer;