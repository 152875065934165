import React, { ButtonHTMLAttributes } from 'react';



import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Container, ButtonField } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({ children, loading, ...rest }) => {
    return (
        <Container>
            <ButtonField type="button" {...rest}>
                {loading ? (
                    <Loader
                        type="ThreeDots"
                        color="#ffffff"
                        height={35}
                        width={35}
                        timeout={0} //3 secs
                    />
                ) : children}
            </ButtonField>
        </Container>
    );
}

export default Button;