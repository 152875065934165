import React, { InputHTMLAttributes } from 'react';

import { InputField } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLElement> {
    placeholder: string;
    type: string;
}

const Input: React.FC<InputProps> = ({ ...rest }) => {
    return (
        <InputField {...rest} />
    );
}

export default Input;
