import React from 'react';

import { Container, Backdrop, Wrapper, Content } from './styles';

interface ModalProps {
    modalShow: boolean;
}

const Modal: React.FC<ModalProps> = ({ modalShow, children }) => {
    return (
        <Container>
            {modalShow && (
                <>
                    <Backdrop />
                    <Wrapper>
                        <Content>{children}</Content>
                    </Wrapper>
                </>
            )}
        </Container>
    );
}

export default Modal;