import React, { useState } from 'react';

import { Link, useHistory } from 'react-router-dom';

import api from '../../services/api';
import { useToast } from '../../hooks/ToastContext';

import { useAuth } from '../../hooks/AuthContext';

import Header from '../../components/Header';

import {
    Container,
    Content,
    ButtonRegister,
    Form,
    InputText,
    Label,
    ContentInput,
    ButtonClose,
    ContentButtons,
    ContentForm,
    TitleModalCustomer,
    ContentBody,
    Select,
    Option
} from './styles';

const UserRegister: React.FC = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [balance, setBalance] = useState('');
    const [isAdmin, setIsAdmin] = useState<number>();
    const [loading, setLoading] = useState(false);

    const { addToast } = useToast();
    const { user } = useAuth();

    const history = useHistory();

    if (user.isAdmin === 0) {
        history.push('/');
    }

    function handleSubmit() {
        if (name === '' || email === '' || phone === '' || password === '' || balance === '' || isAdmin === undefined) {
            addToast({
                type: 'error',
                title: 'Dados incompletos',
                description: 'Todos os campos são obrigatórios'
            });
        } else {
            setLoading(true);
            api.post('users', {
                name, email, phone, password, balance, isAdmin
            }).then(response => {
                setLoading(false);
                addToast({
                    type: 'success',
                    title: 'Cliente cadastrado com sucesso!'
                });

                history.push('/users');
            });
        }
    }

    return (
        <Container>
            <Header />
            <Content>
                <ContentBody>
                    <Form>
                        <TitleModalCustomer>Cadastro de usuário</TitleModalCustomer>
                        <ContentForm>
                            <ContentInput>
                                <Label>Nome:</Label>
                                <InputText
                                    type="text"
                                    placeholder="Ex: João Silva"
                                    onChange={e => setName((e.target as HTMLInputElement).value)}
                                />
                            </ContentInput>

                            <ContentInput>
                                <Label>E-mail:</Label>
                                <InputText
                                    type="email"
                                    placeholder="Ex: contato@agenciagram.com.br"
                                    onChange={e => setEmail((e.target as HTMLInputElement).value)}
                                />
                            </ContentInput>

                            <ContentInput>
                                <Label>WhatsApp:</Label>
                                <InputText
                                    type="text"
                                    placeholder="Ex: (00) 99999-0000"
                                    onChange={e => setPhone((e.target as HTMLInputElement).value)}
                                />
                            </ContentInput>

                            <ContentInput>
                                <Label>Saldo:</Label>
                                <InputText
                                    type="text"
                                    placeholder="Ex: R$ 500,00"
                                    onChange={e => setBalance((e.target as HTMLInputElement).value)}
                                />
                            </ContentInput>

                            <ContentInput>
                                <Label>Nível:</Label>
                                <Select onChange={e => setIsAdmin(Number((e.target as HTMLSelectElement).value))}>
                                    <Option value={Number(2)} disabled selected>Selecione o nível de acesso</Option>
                                    <Option value={1}>Administrator</Option>
                                    <Option value={0}>Usuário</Option>
                                </Select>
                            </ContentInput>

                            <ContentInput>
                                <Label>Senha:</Label>
                                <InputText
                                    type="text"
                                    placeholder="Digite a senha"
                                    onChange={e => setPassword((e.target as HTMLInputElement).value)}
                                />
                            </ContentInput>
                        </ContentForm>

                        <ContentButtons>
                            <Link to="/users">
                                <ButtonClose>Voltar</ButtonClose>
                            </Link>
                            <ButtonRegister
                                loading={loading}
                                onClick={() => handleSubmit()}
                            >Cadastrar</ButtonRegister>
                        </ContentButtons>
                    </Form>
                </ContentBody>
            </Content>
        </Container>
    );
}

export default UserRegister;