import styled from 'styled-components';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Modal from '../../components/Modal';

export const Container = styled.div`
    height: 100vh;
`;

export const Content = styled.div`
    background: #F3F3F3;
    max-width: 90%;
    margin: 0 auto;
    border-radius: 5px;
    min-height: 89%;
    padding: 10px;
`;

export const Form = styled.form`
    
`;

export const ContentForm = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
`;

export const InputText = styled(Input)`
    min-width: 320px;
    height: 40px;
`;

export const Label = styled.span`
    margin: 10px 0 3px 0;
`;

export const ContentInput = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContentButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
`;

export const ButtonRegister = styled(Button)`
    height: 40px;
    width: 160px;
    font-weight: 600;
    margin-left: 20px;
`;

export const ButtonClose = styled(Button)`
    height: 40px;
    width: 110px;
    font-weight: 600;
    margin-left: 50px;
    background: #F3F3F3;
    color: #A8A8B3;
    border: 2px solid #A8A8B3;
`;

export const TitleModalCustomer = styled.span`
    font-size: 18px;
    color: #902DB9;
    margin-bottom: 15px;    
`;

export const TitleDiscountUserPlan = styled.span`
    font-size: 16px;
    color: #902DB9;
`;

export const ContentBody = styled.div`
    width: 80%;
    margin: 0 auto;
`;

export const Select = styled.select`
    height: 40px;
    background: #FFFFFF;
    border-radius: 5px;
    padding-left: 17px;
    margin: 3px 0;
    font-size: 12px;
    min-width: 320px;
    color: #696C74;
    border: 0;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: #525354;
    }
    :-ms-input-placeholder {
        color: #525354;
    }
`;

export const Option = styled.option`

`;

export const ContentPlans = styled.div`
    margin-top: 30px;
    width: 600px;
`;

export const RowPlan = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
`;

export const DescriptionPlan = styled.span`
    font-size: 15px;
`;

export const InputValuePlan = styled(Input)`
    height: 30px;
    padding: 10px;
    border-radius: 3px;
    width: 80px;
    color: #525354;
`;

export const ContentTitlePlanAndAdd = styled.div`
    background: #EBEBEB;
    margin-top: 30px;
    padding: 5px 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ButtonAddPlan = styled(Button)`
    font-size: 20px;
    height: 25px;
    width: 25px;
`;

export const ContentHistoric = styled.div`
    width: 500px;
    margin-top: 30px;
`;

export const RowPayment = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
`;

export const DatePayment = styled.span`
    font-size: 15px;
`;

export const MethodPayment = styled.span`
    font-size: 15px;
`;

export const ValuePayment = styled.span`
    font-size: 15px;
`;

export const ModalAddPlanWithDiscount = styled(Modal)``;

export const ModalAddFunds = styled(Modal)``;