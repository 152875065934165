import React from 'react';

// import { Link } from 'react-router-dom';

// import api from '../../services/api';

import Header from '../../components/Header';
// import Modal from '../../components/Modal';

import * as S from './styles';

// interface IUsers {
//     id: string;
//     created_at: Date;
//     email: string;
//     phone: string;
//     balance: number;
//     isAdmin: number;
//     isActive: boolean;
// }

const Financial: React.FC = () => {

    // const [users, setUsers] = useState<IUsers[]>([]);

    // useEffect(() => {
    //     api.get('users').then(response => {
    //         setUsers(response.data);
    //     })
    // }, []);

    // function formatDate(dataCurrent: Date) {

    //     const date = String(dataCurrent).replace('.', 'T').split("T");
    //     const dateFormated = `${date[0]} ${date[1]}`;

    //     return dateFormated;
    // }

    return (
        <S.Container>
            <Header />
            <S.Content>

                <S.ContentListHeader>
                    <S.ColumnDate>
                        <h1>DATA DE CADASTRO</h1>
                    </S.ColumnDate>
                    <S.ColumnEmail>
                        <h1>USUÁRIO</h1>
                    </S.ColumnEmail>
                    <S.ColumnPhone>
                        <h1>FORMA DE PAGAMENTO</h1>
                    </S.ColumnPhone>
                    <S.ColumnBalance>
                        <h1>VALOR</h1>
                    </S.ColumnBalance>

                </S.ContentListHeader>

                <S.ContentList>
                    <S.ColumnDate>
                        <h1>2021-04-17 17:58:45</h1>
                    </S.ColumnDate>
                    <S.ColumnEmail>
                        <h1>tes2te2@gmail.com</h1>
                    </S.ColumnEmail>
                    <S.ColumnPhone>
                        <h1>TED</h1>
                    </S.ColumnPhone>
                    <S.ColumnBalance>
                        <h1>R$ 1.000,00</h1>
                    </S.ColumnBalance>
                </S.ContentList>

                <S.ContentList>
                    <S.ColumnDate>
                        <h1>2021-04-17 17:58:45</h1>
                    </S.ColumnDate>
                    <S.ColumnEmail>
                        <h1>gaspar.desenvolvimento@gmail.com</h1>
                    </S.ColumnEmail>
                    <S.ColumnPhone>
                        <h1>TED</h1>
                    </S.ColumnPhone>
                    <S.ColumnBalance>
                        <h1>R$ 1.000,00</h1>
                    </S.ColumnBalance>
                </S.ContentList>

                <S.ContentList>
                    <S.ColumnDate>
                        <h1>2021-04-17 17:58:45</h1>
                    </S.ColumnDate>
                    <S.ColumnEmail>
                        <h1>gaspar.desenvolvimeasdadasnto@gmasdadsail.com</h1>
                    </S.ColumnEmail>
                    <S.ColumnPhone>
                        <h1>TED</h1>
                    </S.ColumnPhone>
                    <S.ColumnBalance>
                        <h1>R$ 1.000,00</h1>
                    </S.ColumnBalance>
                </S.ContentList>

            </S.Content>
        </S.Container>
    );
}

export default Financial;