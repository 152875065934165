import styled from 'styled-components';

export const InputField = styled.input`
    height: 60px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 20px;
    margin: 3px 0;
    font-size: 12px;
    color: #696C74;;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: #696C73;
    }
    :-ms-input-placeholder {
        color: #696C73;
    }
`;