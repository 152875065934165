import React, { useState, useEffect } from 'react';

import api from '../../services/api';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import Header from '../../components/Header';

import * as S from './styles';

interface IFunds {
    created_at: Date;
    description: string;
    amount: number;
}

const User: React.FC = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [balance, setBalance] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const [funds, setFunds] = useState<IFunds[]>([]);

    const { user } = useAuth();
    const { addToast } = useToast();

    useEffect(() => {
        api.get(`users/${user.id}`).then(response => {
            const { name, email, phone, balance } = response.data;
            setName(name);
            setEmail(email);
            setPhone(phone);
            setBalance(balance);
        });
    }, [user.id]);

    useEffect(() => {
        api.get(`users/funds/${user.id}`).then(response => {
            setFunds(response.data);
        });
    }, [user.id, loading]);

    function formatDate(dataCurrent: Date) {

        const date = String(dataCurrent).replace('.', 'T').split("T");
        const dateFormated = `${date[0]} ${date[1]}`;

        return dateFormated;
    }

    function handleUpdateProfile() {
        setLoading(true);
        api.put('users', {
            user_id: user.id,
            name,
            phone,
            password
        }).then(response => {
            if (response.status === 204) {
                setLoading(false);
                setPassword('');

                addToast({
                    type: 'success',
                    title: 'Cadastro alterado com sucesso'
                });
            } else {
                setLoading(false);

                addToast({
                    type: 'error',
                    title: 'Não foi possível alterar! Tente novamente'
                });
            }
        });
    }

    return (
        <S.Container>
            <Header />
            <S.Content>
                <S.ContentBody>
                    <S.ContentTitlePlanAndAdd>
                        <S.TitleDiscountUserPlan>Informações do usuário:</S.TitleDiscountUserPlan>
                    </S.ContentTitlePlanAndAdd>
                    <S.Form>
                        <S.ContentForm>
                            <S.ContentInput>
                                <S.Label>Nome:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={name}
                                    placeholder="Ex: João Silva"
                                    onChange={e => setName((e.target as HTMLInputElement).value)}
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>E-mail:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={email}
                                    placeholder="Ex: contato@agenciagram.com.br"
                                    onChange={e => setEmail((e.target as HTMLInputElement).value)}
                                    disabled
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>WhatsApp:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={phone}
                                    placeholder="Ex: (00) 99999-0000"
                                    onChange={e => setPhone((e.target as HTMLInputElement).value)}
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>Saldo:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={"R$ " + (+balance).toFixed(2).replace('.', ',')}
                                    placeholder="Ex: R$ 500,00"
                                    onChange={e => setBalance((e.target as HTMLInputElement).value)}
                                    disabled
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>Senha:</S.Label>
                                <S.InputText
                                    type="password"
                                    placeholder="Digite a senha"
                                    value={password}
                                    onChange={e => setPassword((e.target as HTMLInputElement).value)}
                                />
                            </S.ContentInput>
                        </S.ContentForm>
                    </S.Form>
                </S.ContentBody>

                <S.ContentBody>
                    <S.ContentTitlePlanAndAdd>
                        <S.TitleDiscountUserPlan>Histórico de pagamentos:</S.TitleDiscountUserPlan>
                    </S.ContentTitlePlanAndAdd>

                    <S.ContentHistoric>
                        {funds.map(fund => (
                            <S.RowPayment>
                                <S.DatePayment>{formatDate(fund.created_at)}</S.DatePayment>
                                <S.MethodPayment>{fund.description}</S.MethodPayment>
                                <S.ValuePayment>{"R$ " + (+fund.amount).toFixed(2).replace('.', ',')}</S.ValuePayment>
                            </S.RowPayment>
                        ))}
                    </S.ContentHistoric>

                    <S.ContentButtons>
                        <S.ButtonRegister
                            loading={loading}
                            onClick={() => handleUpdateProfile()}
                        >Salvar</S.ButtonRegister>
                    </S.ContentButtons>
                </S.ContentBody>
            </S.Content>
        </S.Container>
    );
}

export default User;