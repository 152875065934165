import React from 'react';

import { Container, Content, Title, Description } from './styles';

import Header from '../../components/Header';

const Dashboard: React.FC = () => {
    return (
       <Container>
           <Header />
           <Content>
                <Title>Seja bem vindo</Title>
                <Description>Ainda estamos em desenvolvimento para melhor atender! Se identificar algum erro, por favor nos avise! Obrigado!</Description>
           </Content>
      </Container>
    );
}

export default Dashboard;