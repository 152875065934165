import styled from 'styled-components';

export const Container = styled.div`
    height: 100vh;
`;

export const Content = styled.div`
    background: #F3F3F3;
    max-width: 90%;
    margin: 0 auto;
    border-radius: 5px;
    min-height: 89%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Title = styled.h1`
    font-size: 50px;
`;

export const Description = styled.p`
    font-size: 16px;
    margin-top: 20px;
`;