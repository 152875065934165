import React, { useState, FormEvent } from 'react';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import { Container, ContentLogin, ContentText, Text, ContentForm, Form, InputText, ButtonSubmit } from './styles';

export interface UserEvent {
    target: HTMLInputElement;
}

const SignIn: React.FC = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const { signIn } = useAuth();
    const { addToast } = useToast();

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();
        try {

            if (email === '' || password === '') {
                addToast({
                    type: 'error',
                    title: 'E-mail ou senha em branco',
                    description: 'Todos os campos são obrigatórios'
                });
            } else {
                setLoading(true);
                await signIn({
                    email,
                    password
                });

                addToast({
                    type: 'success',
                    title: 'Seja bem-vindo(a)!',
                    description: 'Obrigado por nos escolher!'
                });
                setLoading(false);
            }

        } catch (error) {
            if (error.response.data) {
                setLoading(false);
                addToast({
                    type: 'error',
                    title: 'Dados não conferem',
                    description: 'E-mail ou senha incorreto'
                })
            }
        }
    }

    return (
        <Container>
            <ContentLogin>
                <ContentText>
                    <Text>Ganhe dinheiro revendendo nossos serviços</Text>
                </ContentText>
                <ContentForm>
                    <Form onSubmit={handleSubmit}>
                        <InputText
                            type="email"
                            placeholder="Digite seu e-mail"
                            onChange={e => setEmail((e.target as HTMLInputElement).value)}
                        />
                        <InputText
                            type="password"
                            placeholder="Digite sua senha"
                            onChange={e => setPassword((e.target as HTMLInputElement).value)}
                        />

                        <ButtonSubmit
                            loading={loading}
                            type="submit"
                        >Acessar</ButtonSubmit>
                    </Form>
                </ContentForm>
            </ContentLogin>
        </Container>
    );
}

export default SignIn;