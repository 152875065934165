import styled from 'styled-components';

import Input from '../../components/Input';
import Button from '../../components/Button';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

export const ContentLogin = styled.div`
    display: flex;
    max-width: 60%;
    flex-direction: row;
`;

export const ContentText = styled.div`
    display: flex;
    padding: 30px;
    align-items: center;
`;

export const Text = styled.h1`
    font-size: 35px;
`;

export const ContentForm = styled.div`
    background: #E5E5E5;
    min-width: 400px;
    padding: 50px 15px;
    border-radius: 5px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

export const InputText = styled(Input)`
    margin: 7px 0;
`;

export const ButtonSubmit = styled(Button)`
    width: 100%;
    margin-top: 15px;

     &:hover {
        background: #902DB9;
    }
`;