import styled from 'styled-components';

import { FiChevronRight } from 'react-icons/fi';
import { FiRotateCw } from 'react-icons/fi';

import Input from '../../components/Input';
import Button from '../../components/Button';

import Modal from '../../components/Modal';

interface ButtonProps {
    bg: string;
}

interface BorderProps {
    border: string;
}

export const Container = styled.div`
    height: 100vh;
`;

export const Content = styled.div`
    background: #F3F3F3;
    max-width: 90%;
    margin: 0 auto;
    border-radius: 5px;
    min-height: 89%;
    padding: 10px;
`;

export const ContentFilters = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const ContentList = styled.div<BorderProps>`
    width: 100%;
    display: flex;
    color: #000000;
    padding: 8px 0;
    flex-direction: row;
    background: #fff;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    align-items: center;
    color: #A8A8B3;

    /* border-left: 5px solid green; */

    border-left: 5px solid ${props => props.border};

    /* box-shadow: -1px 1px 5px 0px rgba(133,133,133,0.65);
    -webkit-box-shadow: -1px 1px 5px 0px rgba(133,133,133,0.65);
    -moz-box-shadow: -1px 1px 5px 0px rgba(133,133,133,0.65); */

    :hover {
        background: #F8F8F8;
    }
`;

export const ContentListHeader = styled.div`
    width: 100%;
    display: flex;
    color: #000000;
    padding: 8px 0;
    flex-direction: row;
    padding: 15px 10px;
    border-radius: 5px;
    color: #A8A8B3;
    /* padding: 0 10px; */
`;

export const Column = styled.div`
    display: flex;
    
    h1 {
        font-size: 14px;
        font-weight: 600;
    }

    h2 {
        font-size: 14px;
        font-weight: 500;
    }
`;


export const ColumnId = styled.div`
    width: 16%; 

    h1 {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const ColumnDateHeader = styled.div`
    width: 15%; 
    margin-left: 8px;

    h1 {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const ColumnDate = styled.div`
    width: 15%; 

    h1 {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const ColumnName = styled.div`
    width: 20%;

    h1 {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const ColumnEmail = styled.div`
    width: 20%;

    h1 {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const ColumnLevel = styled.div`
    width: 20%;

    h1 {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const ColumnProfile = styled.div`
    width: 20%;
    h1 {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const ColumnPriceSale = styled.div`
    width: 20%;
    h1 {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const ColumnStatusHeader = styled.div`
    width: 12%;
    h1 {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const ColumnActionsHeader = styled.div`
    h1 {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const ColumnStatus = styled.div`
    width: 8%;

    h1 {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const ColumnActions = styled.div`
    width: 8%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    h1 {
        font-size: 14px;
        font-weight: 600;
    }
`;

export const IconFiChevronRight = styled(FiChevronRight)`
    font-size: 35px;
    color: #7D4196;
    cursor: pointer;
`;

export const IconFiRotateCw = styled(FiRotateCw)`
    font-size: 27px;
    color: green;
    cursor: pointer;
`;

export const ContentFiltersAndRegister = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
`;

export const TextInfo = styled.span`
    font-size: 15px;
`;

export const ButtonFilter = styled(Button) <ButtonProps>`
    height: 40px;
    width: 110px;
    font-weight: 600;
    margin: 0 15px;
    color: ${props => props.bg === '#FFFFFF' ? '#902DB9' : ''};
    border: 2px solid #902DB9;
    background: ${props => props.bg};
`;

export const InputSearch = styled(Input)`
    height: 40px;
    width: 400px;
`;

export const ButtonRegisterCustomer = styled(Button)`
    height: 40px;
    width: 160px;
    font-weight: 600;
    margin-left: 50px;
`;

export const Form = styled.form`
    
`;

export const ContentForm = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
`;

export const InputText = styled(Input)`
    min-width: 320px;
    height: 40px;
`;

export const Label = styled.span`
    margin: 10px 0 3px 0;
`;

export const ContentInput = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContentButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
`;

export const ButtonRegister = styled(Button)`
    height: 40px;
    width: 160px;
    font-weight: 600;
    margin-left: 20px;
`;

export const ButtonClose = styled(Button)`
    height: 40px;
    width: 110px;
    font-weight: 600;
    margin-left: 50px;
    background: #F3F3F3;
    color: #A8A8B3;
    border: 2px solid #A8A8B3;
`;

export const TitleModalCustomer = styled.span`
    font-size: 18px;
    color: #902DB9;
    margin-bottom: 15px;    
`;

export const Select = styled.select`
    height: 40px;
    background: #FFFFFF;
    border-radius: 5px;
    padding-left: 17px;
    margin: 3px 0;
    font-size: 12px;
    min-width: 320px;
    color: #696C74;
    border: 0;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: #525354;
        /* padding: 10px; */
    }
    :-ms-input-placeholder {
        color: #525354;
        /* padding: 10px; */
    }
`;

export const Option = styled.option`
    /* color: #A8A8B3; */
`;

export const ModalRenew = styled(Modal)`

`;

export const TitleDiscountUserPlan = styled.span`
    font-size: 16px;
    color: #902DB9;
`;