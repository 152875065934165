import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Modal from '../../components/Modal';

import * as S from './styles';

interface IUsers {
    id: string;
    created_at: Date;
    email: string;
    phone: string;
    balance: number;
    isAdmin: number;
    isActive: boolean;
}

const Users: React.FC = () => {
    const [activeBgColor, setActiveBgColor] = useState('#FFFFFF');
    const [inactiveBgColor, setInactiveBgColor] = useState('#FFFFFF');
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [users, setUsers] = useState<IUsers[]>([]);

    useEffect(() => {
        api.get('users').then(response => {
            setUsers(response.data);
        })
    }, []);

    function handleActive() {
        setActiveBgColor('#902DB9');
        setInactiveBgColor('#FFFFFF');
    }

    function handleInactive() {
        setInactiveBgColor('#902DB9');
        setActiveBgColor('#FFFFFF');
    }

    function formatDate(dataCurrent: Date) {

        const date = String(dataCurrent).replace('.', 'T').split("T");
        const dateFormated = `${date[0]} ${date[1]}`;

        return dateFormated;
    }

    return (
        <S.Container>
            <Header />
            <S.Content>
                <S.ContentFiltersAndRegister>
                    <S.TextInfo>Filtrar por:</S.TextInfo>

                    <S.ButtonFilter
                        bg={activeBgColor}
                        onClick={() => handleActive()}
                    >Ativo</S.ButtonFilter>
                    <S.ButtonFilter
                        bg={inactiveBgColor}
                        onClick={() => handleInactive()}
                    >Inativo</S.ButtonFilter>

                    <S.InputSearch type="text" placeholder="Pesquisa por perfil" />

                    <Link to="/user-register">
                        <S.ButtonRegisterCustomer>Cadastrar usuário</S.ButtonRegisterCustomer>
                    </Link>
                </S.ContentFiltersAndRegister>

                <S.ContentListHeader>
                    <S.ColumnDate>
                        <h1>DATA DE CADASTRO</h1>
                    </S.ColumnDate>
                    <S.ColumnEmail>
                        <h1>E-MAIL</h1>
                    </S.ColumnEmail>
                    <S.ColumnPhone>
                        <h1>WHATSAPP</h1>
                    </S.ColumnPhone>
                    <S.ColumnBalance>
                        <h1>SALDO</h1>
                    </S.ColumnBalance>
                    <S.ColumnLevel>
                        <h1>NÍVEL</h1>
                    </S.ColumnLevel>
                    <S.ColumnStatusHeader>
                        <h1>STATUS</h1>
                    </S.ColumnStatusHeader>
                </S.ContentListHeader>

                {users.map(user => (
                    <S.ContentList>
                        <S.ColumnDate>
                            <h1>{formatDate(user.created_at)}</h1>
                        </S.ColumnDate>
                        <S.ColumnEmail>
                            <h1>{user.email}</h1>
                        </S.ColumnEmail>
                        <S.ColumnPhone>
                            <h1>{user.phone}</h1>
                        </S.ColumnPhone>
                        <S.ColumnBalance>
                            <h1>{"R$ " + (+user.balance).toFixed(2).replace('.', ',')}</h1>
                        </S.ColumnBalance>
                        <S.ColumnLevel>
                            <h1>{user.isAdmin ? 'Administrador' : 'Usuário'}</h1>
                        </S.ColumnLevel>
                        <S.ColumnActions>
                            <h1>{user.isActive ? 'Ativo' : 'Inativo'}</h1>
                        </S.ColumnActions>
                        <Link to={`/user/${user.id}`}>
                            <S.IconFiChevronRight />
                        </Link>
                    </S.ContentList>
                ))}
            </S.Content>

            <Modal
                modalShow={modalShow}
            >
                <S.Form>
                    <S.TitleModalCustomer>Cadastro de cliente</S.TitleModalCustomer>
                    <S.ContentForm>
                        <S.ContentInput>
                            <S.Label>Nome:</S.Label>
                            <S.InputText type="text" placeholder="Ex: João Silva" />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>E-mail:</S.Label>
                            <S.InputText type="text" placeholder="Ex: contato@agenciagram.com.br" />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>WhatsApp:</S.Label>
                            <S.InputText type="text" placeholder="Ex: (00) 99999-0000" />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>Perfil:</S.Label>
                            <S.InputText type="text" placeholder="Ex: agenciagram" />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>Plano:</S.Label>
                            <S.InputText type="text" placeholder="Selecione o plano" />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>Valor:</S.Label>
                            <S.InputText type="text" placeholder="Ex: R$ 17,00" />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>Valor sugerido:</S.Label>
                            <S.InputText type="text" placeholder="Ex: R$ 49,90" />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>Quantidade por dia:</S.Label>
                            <S.InputText type="text" placeholder="De 20 a 30 seguidores" />
                        </S.ContentInput>
                    </S.ContentForm>

                    <S.ContentButtons>
                        <S.ButtonClose
                            onClick={() => setModalShow(false)}
                        >
                            Fechar
                        </S.ButtonClose>

                        <S.ButtonRegister>
                            Cadastrar
                        </S.ButtonRegister>
                    </S.ContentButtons>
                </S.Form>
            </Modal>
        </S.Container>
    );
}

export default Users;