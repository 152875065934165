import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../../services/api';
import { useToast } from '../../hooks/ToastContext';
import { useAuth } from '../../hooks/AuthContext';

import Header from '../../components/Header';
import Modal from '../../components/Modal';

import * as S from './styles';

interface IPlans {
    id: string;
    name: string;
    price: string;
    description_day_quantity: string;
    discount_price: string;
}

interface ICustomers {
    id: string;
    email: string;
    created_at: Date;
    expiry_date: Date;
    last_send: Date;
    profile: string;
    sale_price: number;
    status: string;
    plan_id: string;
}

const Customers: React.FC = () => {
    const [activeBgColor, setActiveBgColor] = useState('#FFFFFF');
    const [expiredBgColor, setExpiredBgColor] = useState('#FFFFFF');
    const [inactiveBgColor, setInactiveBgColor] = useState('#FFFFFF');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setphone] = useState('');
    const [profile, setProfile] = useState('');
    const [idCustomer, setIdCustomer] = useState<string>();
    const [planSelected, setPlanSelected] = useState('');
    const [planId, setPlanId] = useState('');
    const [pricePlan, setPricePlan] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [quantityDay, setQuantityDay] = useState('');
    const [plans, setPlans] = useState<IPlans[]>([]);
    const [planCurrent, setPlanCurrent] = useState<string>();
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalShowRenew, setModalShowRenew] = useState<boolean>(false);
    const [refreshBalance, setRefreshBalance] = useState('');
    const [customers, setCustomers] = useState<ICustomers[]>([]);
    const [filteredCustomers, setFilteredCustomers] = useState<ICustomers[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingRenew, setLoadingRenew] = useState(false);
    const [search, setSearch] = useState('');

    const planSelectedObj = planSelected.split(",");

    const { addToast } = useToast();
    const { user } = useAuth();

    useEffect(() => {
        setPlanId(planSelectedObj[0]);
        setQuantityDay(planSelectedObj[1]);

        setPricePlan(planSelectedObj[3] === "" ? planSelectedObj[2] : planSelectedObj[3]);
    }, [planSelected, planSelectedObj]);

    useEffect(() => {
        api.get('customers').then(response => {
            setCustomers(response.data);
        })
    }, [refreshBalance]);

    useEffect(() => {
        api.get(`plans/${user.id}`).then(response => {
            setPlans(response.data);
        })
    }, [user.id]);

    useEffect(() => {

        setFilteredCustomers(
            customers.filter(customer =>
                customer.email.toLowerCase().includes(search.toLowerCase()) ||
                customer.profile.toLowerCase().includes(search.toLowerCase()) ||
                customer.status.toLowerCase().includes(search.toLowerCase())
            )
        );

    }, [search, customers]);

    function handleActive() {
        setActiveBgColor('#902DB9');
        setExpiredBgColor('#FFFFFF');
        setInactiveBgColor('#FFFFFF');

        setSearch('active');
    }

    function handleAll() {
        setExpiredBgColor('#902DB9');
        setActiveBgColor('#FFFFFF');
        setInactiveBgColor('#FFFFFF');

        setSearch('');

    }

    function handleInactive() {
        setInactiveBgColor('#902DB9');
        setActiveBgColor('#FFFFFF');
        setExpiredBgColor('#FFFFFF');

        setSearch('canceled');
    }

    function handleSubmit() {

        if (name === '' || email === '' || phone === '' || profile === '' || planSelected === '' || salePrice === '') {
            addToast({
                type: 'error',
                title: 'Dados incompletos',
                description: 'Todos os campos são obrigatórios'
            })
        } else {
            setLoading(true);

            api.post('customers', {
                name,
                email,
                phone,
                plan_id: planId,
                sale_price: parseInt(salePrice),
                profile,
                status: 'active',
            }).then(response => {

                if (response.status === 201) {
                    addToast({
                        type: 'success',
                        title: 'Cliente cadastrado com sucesso!'
                    });

                    const random = Math.random().toString(36).substring(7);

                    setPricePlan('');
                    setQuantityDay('');
                    setModalShow(false);
                    setRefreshBalance(random);
                    setLoading(false);
                }
            }).catch(error => {
                if (error.response.data.message && error.response.status === 400) {
                    addToast({
                        type: 'error',
                        title: 'Saldo insuficiente'
                    });
                    setLoading(false);
                }

                if (error.response.status === 500) {
                    addToast({
                        type: 'error',
                        title: 'Aconteceu um erro interno',
                        description: 'Favor entrar em contato com o suporte'
                    });
                    setLoading(false);
                }
            });
        }
    }

    function formatDate(date: any) {

        const dateFull = date.replace('.', 'T').split("T");
        const dateFormated = `${dateFull[0]} ${dateFull[1]}`;

        return dateFormated;
    }

    function renewCustomer(id: string, profile: string, plan_id: string) {

        setModalShowRenew(true);
        setPlanCurrent(plan_id);
        setProfile(profile);
        setIdCustomer(id);
    }

    function handleRenewCustomer() {

        setLoadingRenew(true);

        api.put('customers/renew', {
            customer_id: idCustomer,
            user_id: user.id,
            plan_id: planCurrent
        }).then(response => {
            if (response.status === 204) {
                setLoadingRenew(false);
                setIdCustomer('');
                setProfile('');
                setPlanCurrent('');
                setModalShowRenew(false);

                const random = Math.random().toString(36).substring(7);

                setRefreshBalance(random);

                addToast({
                    type: 'success',
                    title: 'Cliente renovado com sucesso!'
                });
            }
        }).catch(error => {
            if (error.response.data.message && error.response.status === 400) {
                addToast({
                    type: 'error',
                    title: 'Saldo insuficiente'
                });
                setLoadingRenew(false);
            }

            if (error.response.status === 500) {
                addToast({
                    type: 'error',
                    title: 'Aconteceu um erro interno',
                    description: 'Favor entrar em contato com o suporte'
                });
                setLoadingRenew(false);
            }
        });
    }

    function onChange(text: string) {
        setSearch(text);
    }

    return (
        <S.Container>

            <Header newBalance={refreshBalance} />
            <S.Content>
                <S.ContentFiltersAndRegister>
                    <S.TextInfo>Filtrar por:</S.TextInfo>
                    <S.ButtonFilter
                        bg={expiredBgColor}
                        onClick={() => handleAll()}
                    >
                        Todos
                    </S.ButtonFilter>

                    <S.ButtonFilter
                        bg={activeBgColor}
                        onClick={() => handleActive()}
                    >
                        Ativo
                    </S.ButtonFilter>

                    <S.ButtonFilter
                        bg={inactiveBgColor}
                        onClick={() => handleInactive()}
                    >
                        Inativo
                    </S.ButtonFilter>

                    <S.InputSearch
                        type="text"
                        placeholder="Pesquisa por perfil"
                        onChange={text => onChange((text.target as HTMLInputElement).value)}
                    />

                    <S.ButtonRegisterCustomer
                        onClick={() => setModalShow(true)}
                    >
                        Cadastrar cliente
                    </S.ButtonRegisterCustomer>

                </S.ContentFiltersAndRegister>

                <S.ContentListHeader>
                    <S.ColumnDateHeader>
                        <h1>ATIVAÇÃO</h1>
                    </S.ColumnDateHeader>
                    <S.ColumnDate>
                        <h1>VENCIMENTO</h1>
                    </S.ColumnDate>
                    <S.ColumnDate>
                        <h1>ÚLTIMO ENVIO</h1>
                    </S.ColumnDate>
                    <S.ColumnProfile>
                        <h1>PERFIL</h1>
                    </S.ColumnProfile>
                    <S.ColumnPriceSale>
                        <h1>PREÇO DE VENDA</h1>
                    </S.ColumnPriceSale>
                    <S.ColumnStatusHeader>
                        <h1>STATUS</h1>
                    </S.ColumnStatusHeader>
                    <S.ColumnActionsHeader>
                        <h1>AÇÕES</h1>
                    </S.ColumnActionsHeader>
                </S.ContentListHeader>

                {filteredCustomers.map(customer => (
                    <S.ContentList border={customer.status === 'active' ? 'green' : 'red'}>
                        <S.ColumnId>
                            <h1>{formatDate(customer.created_at)}</h1>
                        </S.ColumnId>
                        <S.ColumnDate>
                            <h1>{formatDate(customer.expiry_date)}</h1>
                        </S.ColumnDate>
                        <S.ColumnDate>
                            <h1>{formatDate(customer.last_send)}</h1>
                        </S.ColumnDate>
                        <S.ColumnProfile>
                            <h1>{customer.profile}</h1>
                        </S.ColumnProfile>
                        <S.ColumnPriceSale>
                            <h1>{"R$ " + (+customer.sale_price).toFixed(2).replace('.', ',')}</h1>
                        </S.ColumnPriceSale>
                        <S.ColumnStatus>
                            <h1>{customer.status === 'active' ? 'Ativo' : 'Inativo'}</h1>
                        </S.ColumnStatus>

                        <S.ColumnActions>
                            {customer.status !== 'active' ?
                                (<S.IconFiRotateCw
                                    onClick={() => renewCustomer(customer.id, customer.profile, customer.plan_id)}
                                />
                                ) : ''}

                            <Link to={`/customer/${customer.id}`}>
                                <S.IconFiChevronRight />
                            </Link>
                        </S.ColumnActions>
                    </S.ContentList>
                ))}
            </S.Content>

            <Modal
                modalShow={modalShow}
            >
                <S.Form>
                    <S.TitleModalCustomer>Cadastro de cliente</S.TitleModalCustomer>
                    <S.ContentForm>
                        <S.ContentInput>
                            <S.Label>Nome:</S.Label>
                            <S.InputText
                                type="text"
                                placeholder="Ex: João Silva"
                                onChange={e => setName((e.target as HTMLInputElement).value)}
                            />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>E-mail:</S.Label>
                            <S.InputText
                                type="text"
                                placeholder="Ex: contato@agenciagram.com.br"
                                onChange={e => setEmail((e.target as HTMLInputElement).value)}
                            />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>WhatsApp:</S.Label>
                            <S.InputText
                                type="text"
                                placeholder="Ex: (00) 99999-0000"
                                onChange={e => setphone((e.target as HTMLInputElement).value)}
                            />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>Perfil:</S.Label>
                            <S.InputText
                                type="text"
                                placeholder="Ex: agenciagram"
                                onChange={e => setProfile((e.target as HTMLInputElement).value)}
                            />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>Plano:</S.Label>
                            <S.Select onChange={e => setPlanSelected((e.target as HTMLSelectElement).value)}>
                                <S.Option value={Number(0)} disabled selected>Selecione um plano</S.Option>
                                {plans.map((plan, index) => (
                                    <S.Option key={index} value={[plan.id, plan.description_day_quantity, plan.price, plan.discount_price]}>{plan.name}</S.Option>))}
                            </S.Select>
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>Preço de custo:</S.Label>
                            <S.InputText
                                type="text"
                                placeholder="Ex: R$ 17,00"
                                value={pricePlan !== undefined ? "R$ " + (+pricePlan).toFixed(2).replace('.', ',') : ''}
                                disabled
                            />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>Preço de venda:</S.Label>
                            <S.InputText
                                type="text"
                                placeholder="Ex: R$ 49,90"
                                onChange={e => setSalePrice((e.target as HTMLInputElement).value)}
                            />
                        </S.ContentInput>

                        <S.ContentInput>
                            <S.Label>Quantidade por dia:</S.Label>
                            <S.InputText
                                type="text"
                                placeholder="De 20 a 30 seguidores"
                                // onChange={e => setQuantityDay((e.target as HTMLInputElement).value)}
                                value={quantityDay}
                                disabled
                            />
                        </S.ContentInput>
                    </S.ContentForm>

                    <S.ContentButtons>
                        <S.ButtonClose
                            onClick={() => setModalShow(false)}
                        >Fechar</S.ButtonClose>

                        <S.ButtonRegister
                            loading={loading}
                            onClick={() => handleSubmit()
                            }>Cadastrar</S.ButtonRegister>
                    </S.ContentButtons>
                </S.Form>
            </Modal>

            <S.ModalRenew
                modalShow={modalShowRenew}
            >
                <S.TitleDiscountUserPlan>Renovação de plano</S.TitleDiscountUserPlan>
                <S.ContentInput>
                    <S.ContentInput>
                        <S.Label>Plano:</S.Label>
                        <S.Select
                            onChange={e => setPlanCurrent((e.target as HTMLSelectElement).value)}
                        >
                            {plans.map((plan) => plan.id === planCurrent ? (
                                <>
                                    <S.Option value={plan.id} selected>{plan.name}</S.Option>
                                    <S.Option value={plan.id}>{plan.name}</S.Option>
                                </>
                            ) : (
                                <S.Option value={plan.id}>{plan.name}</S.Option>
                            ))}
                        </S.Select>
                    </S.ContentInput>
                </S.ContentInput>

                <S.ContentInput>
                    <S.Label>Perfil:</S.Label>
                    <S.InputText
                        type="text"
                        placeholder="Ex: agenciagram"
                        value={profile}
                        disabled
                    />
                </S.ContentInput>

                <S.ContentButtons>
                    <S.ButtonClose
                        onClick={() => setModalShowRenew(false)}
                    >Fechar</S.ButtonClose>
                    <S.ButtonRegister
                        loading={loadingRenew}
                        onClick={() => handleRenewCustomer()}
                    >Renovar</S.ButtonRegister>
                </S.ContentButtons>
            </S.ModalRenew>
        </S.Container >
    );
}

export default Customers;