import React, { useState, useEffect } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';

import api from '../../services/api';

import { useToast } from '../../hooks/ToastContext';
import { useAuth } from '../../hooks/AuthContext';

import Header from '../../components/Header';


import * as S from './styles';

interface IParams {
    id: string;
}

interface IPlans {
    id: string;
    name: string;
    description: string;
    discount_price: number;
}

interface IFunds {
    created_at: Date;
    description: string;
    amount: number;
}

const User: React.FC = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [balance, setBalance] = useState('');
    const [isAdmin, setIsAdmin] = useState<number>();
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState<IPlans[]>([]);
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalShowAddFunds, setModalShowAddFunds] = useState<boolean>(false);
    const [planDiscount, setPlanDiscount] = useState('');
    const [priceDiscount, setPriceDiscount] = useState('');
    const [typePayment, setTypePayment] = useState('');
    const [valueFund, setValueFund] = useState('');
    const [funds, setFunds] = useState<IFunds[]>([]);

    const { addToast } = useToast();

    const { user } = useAuth();

    const history = useHistory();

    const { id } = useParams<IParams>();

    useEffect(() => {
        api.get(`users/${id}`).then(response => {
            const { name, email, phone, balance, isAdmin } = response.data;
            setName(name);
            setEmail(email);
            setPhone(phone);
            setBalance(balance);
            setIsAdmin(isAdmin)
        })
    }, [id]);

    useEffect(() => {
        api.get(`plans/${id}`).then(response => {
            setPlans(response.data);
        })
    }, [id, loading]);

    if (user.isAdmin === 0) {
        history.push('/');
    }

    useEffect(() => {
        api.get(`users/funds/${id}`).then(response => {
            setFunds(response.data);
        });
    }, [id, loading]);

    function handleSubmit() {

        setLoading(true);

        if (planDiscount === '' || priceDiscount === '') {

            setLoading(false);

            addToast({
                type: 'error',
                title: 'Favor informar todos os campos'
            });
        } else {
            api.post('plans/add-user-plan', {
                user_id: id,
                plan_id: planDiscount,
                discount_price: priceDiscount
            }).then(response => {
                setLoading(false);
                setModalShow(false);
                setPlanDiscount('');
                setPriceDiscount('');

                addToast({
                    type: 'success',
                    title: 'Plano cadastrado com sucesso!',
                });
            }).catch(error => {
                setLoading(false);

                addToast({
                    type: 'error',
                    title: 'Este plano já está cadastrado!',
                });
            });
        }
    }

    function handleAddFunds() {
        setLoading(true);

        if (typePayment === '' || valueFund === '') {
            setLoading(false);

            addToast({
                type: 'error',
                title: 'Favor informar todos os campos'
            });
        } else {
            api.post('users/addfunds', {
                user_id: id,
                description: typePayment,
                amount: Number(valueFund)
            }).then(response => {
                if (response.status === 201) {

                    setTypePayment('');
                    setValueFund('');
                    setLoading(false);
                    setModalShowAddFunds(false);

                    addToast({
                        type: 'success',
                        title: 'Saldo adicionado com sucesso'
                    });
                } else {
                    setLoading(false);

                    addToast({
                        type: 'error',
                        title: 'Não foi possível adicionar! Tente novamente'
                    });
                }
            })
        }
    }

    function formatDate(dataCurrent: Date) {

        const date = String(dataCurrent).replace('.', 'T').split("T");
        const dateFormated = `${date[0]} ${date[1]}`;

        return dateFormated;
    }

    function handleUpdateUser() {
        setLoading(true);

        api.put('users', {
            user_id: id,
            name,
            phone,
            password
        }).then(response => {
            if (response.status === 204) {
                setLoading(false);
                setPassword('');

                addToast({
                    type: 'success',
                    title: 'Cadastro alterado com sucesso'
                });
            } else {
                setLoading(false);

                addToast({
                    type: 'error',
                    title: 'Não foi possível alterar! Tente novamente'
                });
            }
        });
    }

    return (
        <S.Container>
            <Header />
            <S.Content>
                <S.ContentBody>
                    <S.ContentTitlePlanAndAdd>
                        <S.TitleDiscountUserPlan>Informações do usuário:</S.TitleDiscountUserPlan>
                    </S.ContentTitlePlanAndAdd>
                    <S.Form>
                        <S.ContentForm>
                            <S.ContentInput>
                                <S.Label>Nome:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={name}
                                    placeholder="Ex: João Silva"
                                    onChange={e => setName((e.target as HTMLInputElement).value)}
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>E-mail:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={email}
                                    placeholder="Ex: contato@agenciagram.com.br"
                                    onChange={e => setEmail((e.target as HTMLInputElement).value)}
                                    disabled
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>WhatsApp:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={phone}
                                    placeholder="Ex: (00) 99999-0000"
                                    onChange={e => setPhone((e.target as HTMLInputElement).value)}
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>Saldo:</S.Label>
                                <S.InputText
                                    type="text"
                                    value={"R$ " + (+balance).toFixed(2).replace('.', ',')}
                                    placeholder="Ex: R$ 500,00"
                                    onChange={e => setBalance((e.target as HTMLInputElement).value)}
                                    disabled
                                />
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>Nível:</S.Label>
                                <S.Select onChange={e => setIsAdmin(Number((e.target as HTMLSelectElement).value))}>
                                    <S.Option value={isAdmin} selected>{isAdmin === 0 ? 'Usuário' : 'Administrador'}</S.Option>
                                    <S.Option value={1}>Administrator</S.Option>
                                    <S.Option value={0}>Usuário</S.Option>
                                </S.Select>
                            </S.ContentInput>

                            <S.ContentInput>
                                <S.Label>Senha:</S.Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite a senha"
                                    value={password}
                                    onChange={e => setPassword((e.target as HTMLInputElement).value)}
                                />
                            </S.ContentInput>
                        </S.ContentForm>
                    </S.Form>
                </S.ContentBody>

                <S.ContentBody>
                    <S.ContentTitlePlanAndAdd>
                        <S.TitleDiscountUserPlan>Planos com desconto:</S.TitleDiscountUserPlan>
                        <S.ButtonAddPlan
                            type="button"
                            onClick={() => setModalShow(true)}
                        >+</S.ButtonAddPlan>
                    </S.ContentTitlePlanAndAdd>
                    <S.ContentPlans>

                        {plans.map(plan => plan.discount_price !== null ? (
                            <S.RowPlan>
                                <S.DescriptionPlan>{`${plan.name} - ${plan.description}`}</S.DescriptionPlan>
                                <S.InputValuePlan
                                    type="text"
                                    value={"R$ " + (+plan.discount_price).toFixed(2).replace('.', ',')} placeholder=""
                                    disabled
                                />
                                {/* 
                                    <button>salvar</button> 

                                    Criar uma função para fazer edit ou delete
                                */}
                            </S.RowPlan>
                        ) : '')}
                    </S.ContentPlans>
                </S.ContentBody>

                <S.ContentBody>
                    <S.ContentTitlePlanAndAdd>
                        <S.TitleDiscountUserPlan>Histórico de pagamentos:</S.TitleDiscountUserPlan>
                        <S.ButtonAddPlan
                            type="button"
                            onClick={() => setModalShowAddFunds(true)}
                        >+</S.ButtonAddPlan>
                    </S.ContentTitlePlanAndAdd>

                    <S.ContentHistoric>
                        {funds.map(fund => (
                            <S.RowPayment>
                                <S.DatePayment>{formatDate(fund.created_at)}</S.DatePayment>
                                <S.MethodPayment>{fund.description}</S.MethodPayment>
                                <S.ValuePayment>{"R$ " + (+fund.amount).toFixed(2).replace('.', ',')}</S.ValuePayment>
                            </S.RowPayment>
                        ))}
                    </S.ContentHistoric>

                    <S.ContentButtons>
                        <Link to="/users">
                            <S.ButtonClose>Voltar</S.ButtonClose>
                        </Link>
                        <S.ButtonRegister
                            loading={loading}
                            onClick={() => handleUpdateUser()}
                        >Salvar</S.ButtonRegister>
                    </S.ContentButtons>
                </S.ContentBody>
            </S.Content>

            <S.ModalAddPlanWithDiscount
                modalShow={modalShow}
            >
                <S.TitleDiscountUserPlan>Cadastro de plano com desconto</S.TitleDiscountUserPlan>
                <S.ContentInput>
                    <S.Label>Plano:</S.Label>
                    <S.Select
                        onChange={e => setPlanDiscount((e.target as HTMLSelectElement).value)}
                    >
                        <S.Option value="" disabled selected>Selecione um plano</S.Option>
                        {plans.map((plan, index) => (
                            <S.Option key={index}
                                value={plan.id}
                            >{plan.name}</S.Option>))}
                    </S.Select>
                </S.ContentInput>

                <S.ContentInput>
                    <S.Label>Preço:</S.Label>
                    <S.InputText
                        type="text"
                        placeholder="Ex: R$ 17,00"
                        onChange={e => setPriceDiscount((e.target as HTMLInputElement).value)}
                    />
                </S.ContentInput>

                <S.ContentButtons>
                    <S.ButtonClose
                        onClick={() => setModalShow(false)}
                    >Fechar</S.ButtonClose>
                    <S.ButtonRegister
                        loading={loading}
                        onClick={() => handleSubmit()}
                    >Cadastrar</S.ButtonRegister>
                </S.ContentButtons>
            </S.ModalAddPlanWithDiscount>

            <S.ModalAddFunds
                modalShow={modalShowAddFunds}
            >
                <S.TitleDiscountUserPlan>Adicionar saldo</S.TitleDiscountUserPlan>
                <S.ContentInput>
                    <S.Label>Meio de pagamento:</S.Label>
                    <S.Select
                        onChange={e => setTypePayment((e.target as HTMLSelectElement).value)}
                    >
                        <S.Option value="" disabled selected>Selecione um meio de pagamento</S.Option>
                        <S.Option value="PIX">PIX</S.Option>
                        <S.Option value="TED">TED</S.Option>
                    </S.Select>
                </S.ContentInput>

                <S.ContentInput>
                    <S.Label>Valor:</S.Label>
                    <S.InputText
                        type="text"
                        placeholder="Digite o valor do saldo"
                        value={valueFund}
                        onChange={e => setValueFund((e.target as HTMLInputElement).value)}
                    />
                </S.ContentInput>

                <S.ContentButtons>
                    <S.ButtonClose
                        onClick={() => setModalShowAddFunds(false)}
                    >Fechar</S.ButtonClose>
                    <S.ButtonRegister
                        loading={loading}
                        onClick={() => handleAddFunds()}
                    >Cadastrar</S.ButtonRegister>
                </S.ContentButtons>
            </S.ModalAddFunds>

        </S.Container>
    );
}

export default User;