import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../hooks/AuthContext';

import api from '../../services/api';

import { ContainerHeader, Logo, Menu, Account, AccountInfo } from './styles';

import iconUser from '../../assets/images/icon_user.svg';
import logo from '../../assets/images/logo.svg';

interface IBalance {
    newBalance?: string;
}

const Header: React.FC<IBalance> = ({newBalance}) => {

    const { user, signOut } = useAuth();
    const [balance, setBalance] = useState<number>(0);

    useEffect(() => {
        api.get('/users/balance').then(response => {
            setBalance(response.data.balance);
        });
    }, [newBalance]);

    return (
        <ContainerHeader>
            <Logo src={logo} />

            <Menu>
                <nav>
                    {user.isAdmin === 1 ? (
                        <>
                            <Link to="/dashboard">Início</Link>
                            <Link to="/customers">Clientes</Link>
                            <Link to="/users">Usuários</Link>
                            <Link to="/financial">Financeiro</Link>
                            <Link to="/profile">Profile</Link>
                            <Link to="" onClick={signOut}>Sair</Link>
                        </>
                    ) : (
                        <>
                            <Link to="/dashboard">Início</Link>
                            <Link to="/customers">Clientes</Link>
                            <Link to="/profile">Profile</Link>
                            {/* <Link to={`/user/${user.id}`}>Profile</Link> */}
                            {/* <Link to="/users">Usuários</Link> */}
                            {/* <Link to="">Financeiro</Link> */}
                            {/* <Link to="">Conta</Link> */}
                            <Link to="" onClick={signOut}>Sair</Link>
                        </>
                    )}
                </nav>
            </Menu>
            <Account>
                <AccountInfo>
                    <h1>{user.name}</h1>
                    <h2>Saldo: {"R$ " + (+balance).toFixed(2).replace('.', ',')}</h2>
                </AccountInfo>
                <AccountInfo>
                    <img src={iconUser} alt="" />
                </AccountInfo>
            </Account>
        </ContainerHeader>
    );
}

export default Header;