import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { Route } from './Route';

import SignIn from '../../src/pages/SignIn';
import Dashboard from '../../src/pages/Dashboard';
import Customers from '../../src/pages/Customers';
import Users from '../../src/pages/Users';
import UserRegister from '../../src/pages/UserRegister';
import User from '../pages/User';
import Customer from '../pages/Customer';
import Profile from '../pages/Profile';
import Financial from '../pages/Financial';

const Routes: React.FC = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" component={SignIn} exact />
                <Route path="/dashboard" component={Dashboard} isPrivate />
                <Route path="/customers" component={Customers} isPrivate />
                <Route path="/users" component={Users} isPrivate />
                <Route path="/user-register" component={UserRegister} isPrivate />
                <Route path="/user/:id" component={User} isPrivate />
                <Route path="/customer/:id" component={Customer} isPrivate />
                <Route path="/profile" component={Profile} isPrivate />
                <Route path="/financial" component={Financial} isPrivate />
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;