import styled from 'styled-components';

export const Container = styled.div`
    /* margin-top: 15px; */
`;

export const ButtonField = styled.button`
    background: #7D4196;
    height: 60px;
    border-radius: 5px;
    border-width: 0;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #FFF;
    font-weight: bold;
    border: none;
    transition: background-color 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    /* &:hover {
        background: #902DB9;
    } */
`;