import styled from 'styled-components';

export const ContainerHeader = styled.div`
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
`;

export const Logo = styled.img`

`;

export const Menu = styled.div`
    display: flex;
    nav {
        a {
            color: #525354;
            font-size: 15.5px;
            padding: 0 15px;
        }
    }
`;

export const Account = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const AccountInfo = styled.div`
    text-align: end;
    
    h1 {
        font-size: 15.5px;
        font-weight: bold;
        color: #525354;
    }

    h2 {
        font-size: 11.5px;
        color: #03A70A; 
        font-weight: 600;
    }

    img {
        margin-left: 10px;
    }
`;